import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import { Flex, Grid } from '@chakra-ui/react'

import { Container, ExternalLink, Text } from 'src/atoms'
import { logout, selectClient } from './authSlice'
import { sanitizeUrl } from 'src/utils/helpers'

const areasMobile = `
  title
  text
  email
  logout
`
/**
 * A functional React component which renders the `Rejected` component.
 *
 * This component is displayed to the user when access to the Astra Platform is rejected/denied
 * and gives the user support options based on the selected `client` and query params of the URL
 */
const Rejected: React.FC = () => {
  const dispatch = useDispatch()
  const client = useSelector(selectClient)

  return (
    <Composition areas={areasMobile} gap={20} as={Container}>
      {Areas => (
        <>
          <Areas.Title>
            <Text fontFamily='heavy' size='large' as='h1'>
              Platform Access
            </Text>
          </Areas.Title>
          <Areas.Text>
            <Box>
              <Text>
                Unfortunately, the information associated with your profile did
                not successfully pass all of our internal security reviews and
                you will not be able to access the Astra platform at this time.
              </Text>
            </Box>
          </Areas.Text>
          <Areas.Email>
            <Grid
              templateColumns='repeat(auto-fit, minmax(200px, 1fr))'
              gap={4}
              as={Flex}
              flexDirection='column'
            >
              {client.supportUrl && (
                <Box>
                  <Text>Find help at:</Text>
                  <ExternalLink href={sanitizeUrl(client.supportUrl)}>
                    {client.name ? `${client.name} Support` : 'Support'}
                  </ExternalLink>
                </Box>
              )}
              {client.supportEmail && (
                <>
                  {client.supportUrl && <Text>or</Text>}
                  <Box>
                    <Text>Contact support via:</Text>
                    <ExternalLink
                      href={`mailto:${sanitizeUrl(client.supportEmail)}`}
                    >
                      {`${client.supportEmail}`}
                    </ExternalLink>
                  </Box>
                </>
              )}
            </Grid>
          </Areas.Email>
          <Areas.Logout marginTop={10}>
            <Text
              color='error'
              cursor='pointer'
              fontFamily='medium'
              size='medium'
              fontWeight='500'
              onClick={() => dispatch(logout({ shouldRedirect: true }))}
            >
              Sign out
            </Text>
          </Areas.Logout>
        </>
      )}
    </Composition>
  )
}

export default Rejected
