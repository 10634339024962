import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Composition } from 'atomic-layout'
import capitalize from 'lodash/capitalize'

import PlaidLink from 'src/features/accounts/PlaidLink'
import Spinner from 'src/components/Spinner'
import {
  ButtonSecondary,
  Container,
  InstitutionLogo,
  ListItem,
  Text,
} from 'src/atoms'

import {
  getInstitutions,
  institutionsSelectors,
  selectInstitutionsLoading,
} from './institutionsSlice'

interface Institution {
  institution_id: string
  logo: string
  name: string
  link_status: string
}

const areasMobile = `
  title
  subtitle
  institutions
  connect
  continue
`

const ConnectInstitutionModule: React.FC = () => {
  const dispatch = useDispatch()
  const institutions: Institution[] = useSelector(
    institutionsSelectors.selectAll,
  )
  const isLoadingInstitutions = useSelector(selectInstitutionsLoading)

  useEffect(() => {
    dispatch(getInstitutions())
  }, [dispatch])

  const renderInstitutions = () => {
    if (isLoadingInstitutions) {
      return (
        <Box
          flex
          alignItems='center'
          justifyContent='center'
          marginVertical={30}
        >
          <Spinner size='small' />
        </Box>
      )
    }

    if (!institutions || institutions.length === 0) {
      return null
    }

    return (
      <Box marginBottom={20}>
        {institutions.map((institution, index) => (
          <ListItem key={institution.institution_id} firstItem={index === 0}>
            <Box flex alignItems='center'>
              <Box marginRight={10}>
                <InstitutionLogo
                  logo={institution.logo}
                  name={institution.name}
                />
              </Box>
              <Text>{institution.name}</Text>
            </Box>
            {institution.link_status === 'error' ? (
              <PlaidLink
                component={ButtonSecondary}
                componentProps={{
                  size: 'small',
                }}
                institutionId={institution.institution_id}
              >
                Reconnect
              </PlaidLink>
            ) : (
              <Text size='small' color='primary'>
                {capitalize(institution.link_status)}
              </Text>
            )}
          </ListItem>
        ))}
      </Box>
    )
  }

  return (
    <Composition areas={areasMobile} as={Container}>
      {({ Title, Subtitle, Institutions, Connect }) => (
        <>
          <Title marginBottom={3}>
            <Text fontFamily='heavy' size='large'>
              Connect Bank
            </Text>
          </Title>
          <Subtitle marginBottom={20}>
            {!isLoadingInstitutions && (
              <Text fontFamily='medium' size='small' color='textSubtle'>
                {institutions && institutions.length > 0
                  ? 'Banks associated with your profile.'
                  : 'To get started, connect a bank account.'}
              </Text>
            )}
          </Subtitle>
          <Institutions>{renderInstitutions()}</Institutions>
          <Connect height={46}>
            <PlaidLink
              component={ButtonSecondary}
              componentProps={{ fullWidth: true }}
            >
              Connect New Bank
            </PlaidLink>
          </Connect>
        </>
      )}
    </Composition>
  )
}

export default ConnectInstitutionModule
