export interface Address {
  address1: string
  address2?: string
  city: string
  state: string
  zip: string
}

export interface BusinessInfo extends Address {
  businessName: string
  doingBusinessAs: string
  businessType: string
  ein: string
  website: string
  phoneNumber: string
}

export interface BusinessAdmin extends Address {
  firstName: string
  lastName: string
  email: string
  dateOfBirth: string
  ssn: string
}

export interface BusinessController extends Address {
  firstName: string
  lastName: string
  email: string
  title: string
  dateOfBirth: string
  ssn: string
}

export interface BeneficialOwner {
  firstName: string
  lastName: string
  dateOfBirth: string
  email: string
  ssn: string
  address1: string
  address2?: string
  city: string
  state: string
  zip: string
}

export interface BusinessProfile {
  businessInfo: BusinessInfo
  businessAdmin: BusinessAdmin
  businessController: BusinessController
}

export enum DocumentStatus {
  APPROVED = 'approved',
  NEEDED = 'needed',
  REJECTED = 'rejected',
  UPLOADED = 'uploaded',
}

export enum KycProviderOptions {
  DWOLLA = 'dwolla',
  PERSONA = 'persona',
  SELF = 'self',
  NONE = '',
}

export enum BusinessType {
  SOLE_PROPRIETORSHIP = 'soleProprietorship',
  UNINCORPORATED_ASSOCIATION = 'soleProprietorship',
  TRUST = 'soleProprietorship',
  CORPORATION = 'corporation',
  PUBLICLY_TRADED_CORPORATION = 'corporation',
  NONPROFIT = 'nonprofit',
  LLC = 'llc',
  PARTNERSHIP = 'partnership',
}

/**
 * A list of business types for designation needed for business verification
 */
export const businessTypes = [
  {
    id: BusinessType.SOLE_PROPRIETORSHIP,
    businessType: BusinessType.SOLE_PROPRIETORSHIP,
    name: 'Sole Proprietorship',
    controllerRequired: false,
    beneficialOwnerRequired: false,
  },
  {
    id: 'unincorporatedAssociation',
    businessType: BusinessType.SOLE_PROPRIETORSHIP,
    name: 'Unincorporated Association',
    controllerRequired: false,
    beneficialOwnerRequired: false,
  },
  {
    id: 'trust',
    businessType: BusinessType.SOLE_PROPRIETORSHIP,
    name: 'Trust',
    controllerRequired: false,
    beneficialOwnerRequired: false,
  },
  {
    id: BusinessType.CORPORATION,
    businessType: BusinessType.CORPORATION,
    name: 'Corporation',
    controllerRequired: true,
    beneficialOwnerRequired: true,
  },
  {
    id: BusinessType.LLC,
    businessType: BusinessType.LLC,
    name: 'LLC',
    controllerRequired: true,
    beneficialOwnerRequired: true,
  },
  {
    id: BusinessType.PARTNERSHIP,
    businessType: BusinessType.PARTNERSHIP,
    name: 'Partnership, LP, LLP',
    controllerRequired: true,
    beneficialOwnerRequired: true,
  },
  {
    id: BusinessType.NONPROFIT,
    businessType: BusinessType.NONPROFIT,
    name: 'Nonprofit',
    controllerRequired: true,
    beneficialOwnerRequired: false,
  },
]

export const getBusinessTypeViaId = (businessTypeId: any) => {
  return businessTypes.filter(b => b.id === businessTypeId)[0]
}
