import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'
import toast from 'react-hot-toast'
import PlaidLink from 'src/features/accounts/PlaidLink'
import logError from 'src/utils/logError'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { selectPlaidLinkOAuth } from 'src/features/auth/authSlice'

const PlaidOAuth: React.FC = () => {
  const [token, setToken] = useState('')
  const [url, setUrl] = useState('')
  const [hasError, setHasError] = useState(false)
  const plaidLinkOAuth = useSelector(selectPlaidLinkOAuth)

  useEffect(() => {
    if (hasError) {
      toast.error('Something went wrong.')
      logError(new Error('plaidLinkOAuth was not found.'))
      navigate('/')
    }
  }, [hasError])

  useEffect(() => {
    if (!isEmpty(plaidLinkOAuth)) {
      const { linkToken, url } = plaidLinkOAuth
      if (linkToken && url) {
        setToken(linkToken)
        setUrl(url)
      } else {
        setHasError(true)
      }
    } else {
      setHasError(true)
    }
  }, [plaidLinkOAuth, token])

  if (!token) return null

  return (
    <PlaidLink
      isOAuth={true}
      linkToken={token}
      receivedRedirectUri={window.location.href}
      redirectToUrl={url}
    />
  )
}

export default PlaidOAuth
