import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { Box } from 'atomic-layout'
import { navigate } from '@reach/router'
import startCase from 'lodash/startCase'
import toast from 'react-hot-toast'

import { getInstitutions, institutionsSelectors } from './institutionsSlice'
import {
  accountsSelectors,
  getAccounts,
  removeAccount,
  selectAccountsLoading,
  selectIsRemovingAccount,
} from 'src/features/accounts/accountsSlice'
import FullScreenSpinner from 'src/components/FullScreenSpinner'
import NotFound from 'src/pages/NotFound'
import DropdownMenu from 'src/components/DropdownMenu'
import PlaidLink from 'src/features/accounts/PlaidLink'
import { ButtonSecondary, Container, InstitutionLogo, Text } from 'src/atoms'
import { convertISOToShortDate, dollarFormat } from 'src/utils/format'

const Row = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`

const Block = styled(Box)`
  flex: 0 0 50%;
  border-right: ${props =>
    props.divider ? `1px solid ${props.theme.colors.border}` : 0};
  padding: 12px 14px;
`

const AccountDetail = ({ id }) => {
  const dispatch = useDispatch()
  const account = useSelector(state => accountsSelectors.selectById(state, id))
  const institution = useSelector(state => {
    if (!account) return null
    return institutionsSelectors.selectById(state, account.institution_item_id)
  })
  const accountsLoading = useSelector(selectAccountsLoading)
  const isRemoving = useSelector(selectIsRemovingAccount)

  useEffect(() => {
    if (!account) dispatch(getAccounts())
    if (!institution) dispatch(getInstitutions())
  }, [dispatch, account, institution])

  useEffect(() => {
    if (institution && institution.link_status === 'error') {
      const errorMessage = 'Please reconnect your bank account'
      toast.error(errorMessage)
    }
  }, [institution])

  if (!account && accountsLoading) {
    return <FullScreenSpinner />
  }

  if (!account) {
    return <NotFound />
  }

  return (
    <Box width='100%'>
      <Box paddingHorizontal={10}>
        <Box flex alignItems='center'>
          <Box flexGrow={1}>
            <Text fontFamily='heavy' size='large' textAlign='left'>
              {account.name}
            </Text>
          </Box>
          <Box flex alignItems='center'>
            {institution && (
              <InstitutionLogo
                logo={institution.logo}
                name={institution.name}
              />
            )}
            <DropdownMenu
              button={
                <ion-icon name='ellipsis-horizontal' style={{ fontSize: 25 }} />
              }
            >
              <DropdownMenu.Item
                onClick={() => {
                  dispatch(removeAccount({ id }))
                    .then(unwrapResult)
                    .then(() => {
                      navigate('/accounts')
                    })
                }}
                name={isRemoving ? 'Removing...' : 'Remove'}
                isDangerous
              />
            </DropdownMenu>
          </Box>
        </Box>
        {institution && (
          <Box>
            <Text
              size='xsmall'
              color={institution.link_status === 'error' ? 'error' : 'primary'}
            >
              {startCase(institution.link_status)}
            </Text>
            {institution.link_status === 'error' && (
              <Box marginTop={5}>
                <PlaidLink
                  component={ButtonSecondary}
                  componentProps={{
                    size: 'small',
                  }}
                  institutionId={institution.institution_id}
                >
                  Reconnect
                </PlaidLink>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Container padding={0} marginTop={20} marginBottom={35}>
        <Row>
          <Block
            flex
            alignItems='center'
            justifyContent='space-between'
            paddingVertical={5}
          >
            <Text color='textSubtle' size='xsmall'>
              {startCase(account.subtype)} - {account.number}
            </Text>
            <Text color='textSubtle' size='xsmall'>
              Updated: {convertISOToShortDate(account.last_balance_update_on)}
            </Text>
          </Block>
        </Row>
        <Box flex>
          <Block divider>
            <Text color='textSubtle' size='xsmall'>
              Available Balance
            </Text>
            <Text fontFamily='heavy' lineHeight='24px' size='large'>
              {account.available_balance &&
              typeof account.available_balance === 'number'
                ? dollarFormat(account.available_balance)
                : 'n/a'}
            </Text>
          </Block>
          <Block>
            <Text color='textSubtle' size='xsmall'>
              Current Balance
            </Text>
            <Text fontFamily='heavy' lineHeight='24px' size='large'>
              {account.current_balance &&
              typeof account.current_balance === 'number'
                ? dollarFormat(account.current_balance)
                : 'n/a'}
            </Text>
          </Block>
        </Box>
      </Container>
    </Box>
  )
}

export default AccountDetail
