import { FormEvent, useEffect, useState } from 'react'
import {
  DeepRequired,
  FieldErrorsImpl,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form'
import { useSelector } from 'react-redux'

import * as Yup from 'yup'
import { phoneValiation, useFormHandler } from 'src/hooks/useFormHandler'
import { useAppDispatch } from 'src/redux/store'
import {
  BusinessType,
  getBusinessTypeViaId,
  KycProviderOptions,
} from 'src/features/verify/types'
import {
  BusinessFlowStepIndex,
  selectBusinessInfo,
  selectKybProvider,
  setBusinessFlowStep,
  setControllerRequired,
  setBusinessInfo,
  setBeneficialOwnerRequired,
} from 'src/features/verify/verifySlice'
import { clearPhoneNumberFormatting, formatPhoneNumber } from 'src/utils/format'

export enum BusinessInfoFormKeys {
  BUSINESS_NAME = 'businessName',
  BUSINESS_TYPE = 'businessType',
  DOING_BUSINESS_AS = 'doingBusinessAs',
  EIN = 'ein',
  PHONE_NUMBER = 'phoneNumber',
  STATUS = 'status',
  WEBSITE = 'website',
}

export interface BusinessInfoFormSchema {
  [BusinessInfoFormKeys.BUSINESS_NAME]: string
  [BusinessInfoFormKeys.BUSINESS_TYPE]: string
  [BusinessInfoFormKeys.DOING_BUSINESS_AS]?: string
  [BusinessInfoFormKeys.EIN]?: string
  [BusinessInfoFormKeys.PHONE_NUMBER]: string
  [BusinessInfoFormKeys.WEBSITE]?: string
}

const businessInfoFormDefaultValues: BusinessInfoFormSchema = {
  [BusinessInfoFormKeys.BUSINESS_NAME]: '',
  [BusinessInfoFormKeys.BUSINESS_TYPE]: '0',
  [BusinessInfoFormKeys.DOING_BUSINESS_AS]: '',
  [BusinessInfoFormKeys.EIN]: '',
  [BusinessInfoFormKeys.PHONE_NUMBER]: '',
  [BusinessInfoFormKeys.WEBSITE]: '',
}

const formConfig: Record<
  KycProviderOptions,
  {
    fieldVisibility: Partial<Record<keyof BusinessInfoFormSchema, boolean>>
    requiredFields: Partial<Record<keyof BusinessInfoFormSchema, boolean>>
  }
> = {
  [KycProviderOptions.DWOLLA]: {
    fieldVisibility: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: true,
      [BusinessInfoFormKeys.EIN]: true,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: true,
    },
    requiredFields: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: false,
      [BusinessInfoFormKeys.EIN]: false,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: false,
    },
  },
  [KycProviderOptions.PERSONA]: {
    fieldVisibility: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: true,
      [BusinessInfoFormKeys.EIN]: true,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: true,
    },
    requiredFields: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: false,
      [BusinessInfoFormKeys.EIN]: false,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: false,
    },
  },
  [KycProviderOptions.SELF]: {
    fieldVisibility: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: true,
      [BusinessInfoFormKeys.EIN]: true,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: true,
    },
    requiredFields: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: false,
      [BusinessInfoFormKeys.EIN]: false,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: false,
    },
  },
  [KycProviderOptions.NONE]: {
    fieldVisibility: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: true,
      [BusinessInfoFormKeys.EIN]: true,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: true,
    },
    requiredFields: {
      [BusinessInfoFormKeys.BUSINESS_NAME]: true,
      [BusinessInfoFormKeys.BUSINESS_TYPE]: true,
      [BusinessInfoFormKeys.DOING_BUSINESS_AS]: false,
      [BusinessInfoFormKeys.EIN]: false,
      [BusinessInfoFormKeys.PHONE_NUMBER]: true,
      [BusinessInfoFormKeys.WEBSITE]: false,
    },
  },
}

const createValidationSchema = (): Yup.SchemaOf<BusinessInfoFormSchema> => {
  return Yup.object().shape({
    [BusinessInfoFormKeys.BUSINESS_NAME]: Yup.string().required(),
    [BusinessInfoFormKeys.BUSINESS_TYPE]: Yup.string().required(),
    [BusinessInfoFormKeys.DOING_BUSINESS_AS]: Yup.string(),
    [BusinessInfoFormKeys.EIN]: Yup.string().when(
      [BusinessInfoFormKeys.BUSINESS_TYPE],
      (businessTypeId: string, schema: any) => {
        let businessType = ''

        // TODO(worstestes - 2.10.23): issues with programatically getting businessType via
        // `getBusinessTypeById`, re-rendering issues, etc.. find a workaround when time permits.
        if (
          businessTypeId === 'unincorporatedAssociation' ||
          businessTypeId === 'soleProprietorship' ||
          businessTypeId === 'trust'
        ) {
          businessType = BusinessType.SOLE_PROPRIETORSHIP
        }

        return businessType !== BusinessType.SOLE_PROPRIETORSHIP
          ? schema
              .required()
              .test(
                'test EIN length',
                'EIN must be up to 9 digits without dashes',
                (text: string) => {
                  const length = text ? text.trim().length : 0
                  if (length < 10) {
                    return false
                  }
                  return true
                },
              )
          : schema
      },
    ),
    [BusinessInfoFormKeys.PHONE_NUMBER]: phoneValiation,
    [BusinessInfoFormKeys.WEBSITE]: Yup.string().url(
      'Please enter a valid website URL. (e.g. https://www.example.org)',
    ),
  })
}

const dontClearErrorsOnSuccess = false

// This option allows you to configure the validation strategy before a user submits the form (onSubmit event).
const formValidationMode: keyof ValidationMode = 'onChange'

/**
 * This hook is used to handle the process of the user filling out the `BusinessInfo` form:
 *
 * * sets initial value of the form field
 * * handles submission of the form, this can include a POST request for the final form submission
 * * exports the `formHandler` (the form API, submit, event handling, etc.) and the `isSubmitDisabled` value
 *   which is used to determine whether the submit button is active.
 * */
export default function useBusinessInfoForm(): BusinessInfoFormReturnType {
  const dispatch = useAppDispatch()

  const kybProvider =
    useSelector(selectKybProvider) || KycProviderOptions.PERSONA
  const selectedFormConfig = formConfig[kybProvider]

  const businessInfo = useSelector(selectBusinessInfo)

  // we set `defaultValues` somewhat asynchronously in the `useEffect` hook below
  // once these are set, another `useEffect` hook in `useFormHandler` hook will use
  // `reset` to reset the form anytime the default values change
  // defaultValues are needed in order to properly handle validation issues
  const [defaultValues, setDefaultValues] = useState<BusinessInfoFormSchema>({
    ...businessInfoFormDefaultValues,
  })

  // TODO(worstestes - 12/3/23): determine how we designate a 'new' form, meaning the user hasn't completed this form before
  const isNewForm = true

  // SET INITIAL/DEFAULT FORM INPUT VALUES
  useEffect(() => {
    if (businessInfo) {
      const formattedPhoneNumber = formatPhoneNumber(
        businessInfo[BusinessInfoFormKeys.PHONE_NUMBER],
      )

      setDefaultValues({
        ...defaultValues,
        [BusinessInfoFormKeys.BUSINESS_NAME]:
          businessInfo[BusinessInfoFormKeys.BUSINESS_NAME],
        [BusinessInfoFormKeys.BUSINESS_TYPE]:
          businessInfo[BusinessInfoFormKeys.BUSINESS_TYPE],
        [BusinessInfoFormKeys.DOING_BUSINESS_AS]:
          businessInfo[BusinessInfoFormKeys.DOING_BUSINESS_AS],
        [BusinessInfoFormKeys.EIN]: businessInfo[BusinessInfoFormKeys.EIN],
        [BusinessInfoFormKeys.PHONE_NUMBER]: formattedPhoneNumber,
        [BusinessInfoFormKeys.WEBSITE]:
          businessInfo[BusinessInfoFormKeys.WEBSITE],
      })

      if (formattedPhoneNumber) {
        formHandler.form.setValue(
          BusinessInfoFormKeys.PHONE_NUMBER,
          formattedPhoneNumber,
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessInfo])

  const onSubmit = (data: BusinessInfoFormSchema) => {
    data.phoneNumber = clearPhoneNumberFormatting(data.phoneNumber)

    const beneficialOwnerRequired = getBusinessTypeViaId(data.businessType)
      .beneficialOwnerRequired
    const controllerRequired = getBusinessTypeViaId(data.businessType)
      .controllerRequired

    dispatch(setBusinessInfo(data))
    dispatch(setBeneficialOwnerRequired(beneficialOwnerRequired))
    dispatch(setControllerRequired(controllerRequired))
    dispatch(setBusinessFlowStep(BusinessFlowStepIndex.ADDRESS))
  }

  const onEdit = () => {
    dispatch(setBusinessFlowStep(BusinessFlowStepIndex.INFO))
  }

  const validationSchema = createValidationSchema()

  const formHandler = useFormHandler<BusinessInfoFormSchema>(
    data => onSubmit(data),
    defaultValues,
    validationSchema,
    dontClearErrorsOnSuccess,
    formValidationMode,
  )

  const { isValid, errors } = formHandler.form.formState

  return {
    ...formHandler,
    defaultValues: formHandler.form.formState
      .defaultValues as BusinessInfoFormSchema,
    errors,
    fieldVisibility: selectedFormConfig.fieldVisibility,
    isNewForm,
    isSubmitDisabled: !isValid,
    onEdit,
    requiredFields: selectedFormConfig.requiredFields,
  }
}

export type BusinessInfoFormReturnType = {
  defaultValues: BusinessInfoFormSchema
  errors: FieldErrorsImpl<DeepRequired<BusinessInfoFormSchema>>
  fieldVisibility: Partial<Record<keyof BusinessInfoFormSchema, boolean>>
  form: UseFormReturn<BusinessInfoFormSchema, any>
  isNewForm: boolean
  isSubmitDisabled: boolean
  onEdit: () => void
  onSubmit: (e?: FormEvent<Element> | undefined) => Promise<void>
  requiredFields: Partial<Record<keyof BusinessInfoFormSchema, boolean>>
}
