export enum localStorageKeys {
  USER_FOR_SIGN_UP = 'userForSignUp',
  PLAID_LINK_OAUTH = 'plaidLinkOAuth',
}

// https://github.com/angular/angular/blob/main/packages/core/src/sanitization/url_sanitizer.ts
const SAFE_URL_PATTERN = /^(?!javascript:)(?:[a-z0-9+.-]+:|[^&:?#]*(?:[?#]|$))/i

const _sanitizeUrl = (url: string): string => {
  const urlString = String(url)
  if (urlString === 'null' || url.length === 0 || urlString === 'about:blank')
    return 'about:blank'
  if (urlString.match(SAFE_URL_PATTERN)) {
    return url
  }

  return `unsafe:${url}`
}

export const sanitizeUrl = (url = 'about:blank'): string => {
  return _sanitizeUrl(String(url).trim())
}
