import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { usePlaidLink } from 'react-plaid-link'
import { navigate, useLocation } from '@reach/router'
import { Composition } from 'atomic-layout'
import styled from 'styled-components'
import toast from 'react-hot-toast'

import { Button, Text } from 'src/atoms'
import Modal from 'src/components/Modal'
import { plaidLogin } from './accountsSlice'
import {
  getInstitutions,
  getOnDemandAuthText,
  selectOnDemandAuth,
} from './institutionsSlice'
import { selectClient, setPlaidLinkOAuth } from 'src/features/auth/authSlice'
import logError from 'src/utils/logError'
import { trackEvent } from 'src/utils/metrics'
import apiRequest from 'src/utils/api'
import {
  sendLinkOpenedMessage,
  sendLinkClosedMessage,
} from 'src/features/sdkMessaging/sdkMessagingSlice'

import { useAppDispatch } from 'src/redux/store'

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.backgroundInverse};
  display: ${(props: { visible: boolean }) =>
    props.visible ? 'block' : 'none'};
  opacity: ${(props: { visible: boolean }) => (props.visible ? 0.8 : 0)};
`

interface PlaidLinkProps {
  isOAuth: boolean
  component?: any
  componentProps?: any
  token: string
  children?: ReactNode
  onSuccess: (publicToken: any, metadata: any) => Promise<void>
  onExit: (err: any) => void
  onEvent: (eventName: any, metadata: any) => void
  onLoad?: () => void
  receivedRedirectUri?: string
}

const PlaidLink: React.FC<PlaidLinkProps> = ({
  isOAuth,
  component: Component,
  componentProps,
  token,
  children,
  onSuccess,
  onExit,
  onEvent,
  onLoad,
  receivedRedirectUri,
}) => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const config = {
    token,
    onSuccess,
    onExit,
    onEvent,
    onLoad,
    env: process.env.REACT_APP_PLAID_ENV,
    receivedRedirectUri: '',
  }
  if (receivedRedirectUri) {
    config.receivedRedirectUri = receivedRedirectUri
  }

  const { open, ready } = usePlaidLink(config)

  useEffect(() => {
    // initiallizes Link automatically if it is handling an OAuth redirect
    if (isOAuth && ready) {
      open()
    }
  }, [ready, open, isOAuth])

  if (isOAuth) return null

  return (
    <Component
      onClick={() => {
        open()
        const plaidLinkOAuth = {
          url: `${location.pathname}${location.search}`,
          linkToken: token,
        }

        dispatch(setPlaidLinkOAuth(plaidLinkOAuth))
      }}
      type='button'
      disabled={!ready}
      {...componentProps}
    >
      {children}
    </Component>
  )
}

interface PlaidLinkContainerProps {
  isOAuth?: boolean
  institutionId?: string
  linkToken?: string
  receivedRedirectUri?: string
  redirectToUrl?: string
  [key: string]: any
}

const PlaidLinkContainer: React.FC<PlaidLinkContainerProps> = ({
  isOAuth = false,
  institutionId,
  linkToken = '',
  receivedRedirectUri,
  redirectToUrl,
  ...rest
}) => {
  const dispatch = useAppDispatch()
  const [token, setToken] = useState(linkToken)
  const [showOnDemandAuthModal, setShowOnDemandAuthModal] = useState(false)
  const [plaidPublicToken, setPlaidPublicToken] = useState('')
  const [plaidMetadata, setPlaidMetadata] = useState({
    institution: { name: '' },
  })
  const [plaidLinkVisible, setPlaidLinkVisible] = useState(false)
  const { clientId } = useSelector(selectClient)
  const onDemandAuth = useSelector(selectOnDemandAuth)

  useEffect(() => {
    let mounted = true
    const getLinkToken = async () => {
      const data = {
        client_id: clientId,
        redirect_uri: `${window.location.origin}/plaid-oauth`,
        institution_id: '',
      }
      if (institutionId) {
        data.institution_id = institutionId
      }
      const { link_token: linkToken } = (await dispatch(
        apiRequest({
          method: 'post',
          route: 'plaid/create_link_token',
          data,
        }),
      ).then(unwrapResult)) as { link_token: string }
      if (mounted) {
        setToken(linkToken)
      }
    }

    // do not get link token if one was already passed in (OAuth flow)
    if (!token) {
      getLinkToken()
    }

    return () => {
      mounted = false
    }
  }, [dispatch, clientId, institutionId, token])

  useEffect(() => {
    dispatch(getOnDemandAuthText())
  }, [dispatch])

  const openOnDemandAuthModal = () => {
    setShowOnDemandAuthModal(true)
  }

  const closeOnDemandAuthModal = () => {
    setShowOnDemandAuthModal(false)
  }

  const onSuccess = useCallback(async (publicToken, metadata) => {
    setPlaidPublicToken(publicToken)
    setPlaidMetadata(metadata)
  }, [])

  const onExit = useCallback(
    error => {
      if (redirectToUrl) {
        navigate(redirectToUrl)
      }
      setPlaidLinkVisible(false)
      dispatch(sendLinkClosedMessage())

      if (error) {
        logError(error)
      }
    },
    [dispatch, redirectToUrl],
  )

  const onEvent = useCallback(
    (eventName, metadata) => {
      if (eventName === 'OPEN') {
        setPlaidLinkVisible(true)
        dispatch(sendLinkOpenedMessage())
      }

      if (eventName === 'HANDOFF') {
        setPlaidLinkVisible(false)
        dispatch(sendLinkClosedMessage())
        openOnDemandAuthModal()
      }

      trackEvent(`Plaid Link - ${eventName}`, {
        event_name: eventName,
        ...metadata,
      })
    },
    [dispatch],
  )

  const agreeOnDemandAuth = async () => {
    closeOnDemandAuthModal()

    if (redirectToUrl) {
      navigate(redirectToUrl)
    }

    const toastId = toast.loading(
      `Connecting ${plaidMetadata.institution.name}...`,
    )

    await dispatch(
      plaidLogin({
        clientId,
        plaidPublicToken,
        plaidMetadata,
      }),
    )

    await dispatch(getInstitutions())

    toast.success(`${plaidMetadata.institution.name} is now connected.`, {
      id: toastId,
    })

    dispatch(setPlaidLinkOAuth({ linkToken: '', url: '' }))
  }

  if (!token) return null

  return (
    <>
      <PlaidLink
        isOAuth={isOAuth}
        token={token}
        onSuccess={onSuccess}
        onExit={onExit}
        onEvent={onEvent}
        receivedRedirectUri={receivedRedirectUri}
        {...rest}
      />
      <Modal
        isOpen={showOnDemandAuthModal}
        close={closeOnDemandAuthModal}
        ariaLabel='Authorize your Bank Accounts'
      >
        <Composition
          areas={`
              title
              subtitle
              onDemandText
              button
            `}
          gap={30}
        >
          {Areas => (
            <>
              <Areas.Title>
                <Text fontFamily='heavy' size='large'>
                  Authorize your Bank Accounts
                </Text>
              </Areas.Title>
              <Areas.Subtitle>
                <Text fontFamily='medium'>
                  In order to create automated Routines and process bank
                  transfers, please authorize your newly connected accounts:
                </Text>
              </Areas.Subtitle>
              <Areas.OnDemandText>
                <Text>{onDemandAuth.bodyText}</Text>
              </Areas.OnDemandText>
              <Areas.Button>
                <Button onClick={agreeOnDemandAuth} fullWidth>
                  {onDemandAuth.buttonText}
                </Button>
              </Areas.Button>
            </>
          )}
        </Composition>
      </Modal>
      <BackgroundOverlay visible={plaidLinkVisible} />
    </>
  )
}

export default PlaidLinkContainer
