import React from 'react'
import { useSelector } from 'react-redux'

import ConnectInstitutionModule from 'src/features/accounts/ConnectInstitutionModule'
import ConnectDebitCardModule from './ConnectDebitCardModule'
import ContinueButton from './ContinueButton'
import {
  selectClient,
  selectCanConnectAccounts,
} from 'src/features/auth/authSlice'

const ConnectAccounts: React.FC = () => {
  const isDebitEnabled = useSelector(selectClient).debitEnabled
  const canConnectAccounts = useSelector(selectCanConnectAccounts)

  return (
    <>
      {canConnectAccounts && <ConnectInstitutionModule />}
      {isDebitEnabled && <ConnectDebitCardModule />}
      <ContinueButton />
    </>
  )
}

export default ConnectAccounts
