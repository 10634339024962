import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  unwrapResult,
} from '@reduxjs/toolkit'
import { navigate } from '@reach/router'
import * as queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import apiRequest from 'src/utils/api'
import { sendClosedMessage } from 'src/features/sdkMessaging/sdkMessagingSlice'
import { setOnboarding } from 'src/features/sharedActions'

import { convertDateToISO } from 'src/utils/format'
import {
  BeneficialOwner,
  BusinessAdmin,
  BusinessController,
  BusinessInfo,
  BusinessType,
  getBusinessTypeViaId,
  KycProviderOptions,
} from 'src/features/verify/types'
import { UserType } from 'src/features/auth/types'

import { RootState } from 'src/redux/store'

export interface DocumentPayloadProps {
  extension: string
  documentType: string
  document: ReadableStream<Uint8Array>
}

const beneficialOwnersAdapter = createEntityAdapter({
  selectId: () => uuidv4(),
})

export const verify = createAsyncThunk(
  'verify',
  async (
    addressData: {
      address1: string
      address2: string
      city: string
      state: string
      zip: string
    },
    { dispatch, getState },
  ) => {
    const {
      verify,
      auth: {
        client: { clientId },
      },
    } = getState() as RootState

    const { dateOfBirth, legalFirstName, legalLastName, ssn } = verify.personal

    const { address1, address2, city, state, zip } = addressData

    await dispatch(
      apiRequest({
        method: 'post',
        route: 'customer/verify',
        data: {
          first_name: legalFirstName,
          last_name: legalLastName,
          date_of_birth: dateOfBirth,
          ssn,
          address1,
          address2,
          city,
          state,
          postal_code: zip,
          client_id: clientId,
        },
      }),
    ).then(unwrapResult)

    dispatch(setOnboarding())

    return addressData
  },
)

export const finishVerifyBusinessProcess = createAsyncThunk(
  'verify/finishVerifyBusinessProcess',
  async (empty, { dispatch, getState }) => {
    const {
      sdkMessaging: { iframeOrigin },
    } = getState() as RootState

    dispatch(setOnboarding())

    const searchQuery = window.location.search

    const { continue_to, redirect_uri, return_to } = queryString.parse(
      searchQuery,
    )

    const continueTo = continue_to as string
    const redirectUri = redirect_uri as string
    const returnTo = return_to as string

    if (iframeOrigin) {
      dispatch(sendClosedMessage())
    } else {
      navigate(continueTo || returnTo || redirectUri || '/')
    }
  },
)
export const determineBeneficialOwners = createAsyncThunk(
  'verify/determineBeneficialOwners',
  async (_, { dispatch, getState }) => {
    const {
      verify: {
        business: { beneficialOwnerRequired },
      },
    } = getState() as RootState

    const searchQuery = window.location.search || ''

    if (beneficialOwnerRequired) {
      navigate(`/verify/beneficial-owners${searchQuery}`)
    } else {
      dispatch(verifyBusiness())
      dispatch(finishVerifyBusinessProcess())
    }
  },
)

const formatVerifyBusinessPayload = ({
  admin,
  beneficialOwners,
  businessType,
  clientId,
  controller,
  info,
  kybProvider,
}: {
  admin: BusinessAdmin
  beneficialOwners: BeneficialOwner[]
  businessType: BusinessType
  clientId: string
  controller: BusinessController
  info: BusinessInfo
  kybProvider: KycProviderOptions
}) => {
  const adminPayload = {
    address: {
      address1: admin.address1,
      address2: admin.address2,
      address3: '',
      city: admin.city,
      postal_code: admin.zip,
      state: admin.state,
      country: 'US',
    },
    date_of_birth: admin.dateOfBirth,
    email: admin.email,
    first_name: admin.firstName,
    last_name: admin.lastName,
    ssn: admin.ssn,
  }

  const controllerPayload =
    businessType === BusinessType.SOLE_PROPRIETORSHIP
      ? null
      : {
          address: {
            address1: controller.address1,
            address2: controller.address2,
            address3: '',
            city: controller.city,
            postal_code: controller.zip,
            state: controller.state,
            country: 'US',
          },
          email: controller.email,
          date_of_birth: controller.dateOfBirth,
          first_name: controller.firstName,
          last_name: controller.lastName,
          ssn: controller.ssn,
          title: controller.title,
        }

  const beneficialOwnersPayload = beneficialOwners.map(beneficialOwner => ({
    address: {
      address1: beneficialOwner.address1,
      address2: beneficialOwner.address2,
      address3: '',
      city: beneficialOwner.city,
      postal_code: beneficialOwner.zip,
      state: beneficialOwner.state,
      country: 'US',
    },
    email: beneficialOwner.email,
    date_of_birth: beneficialOwner.dateOfBirth,
    first_name: beneficialOwner.firstName,
    last_name: beneficialOwner.lastName,
    ssn: beneficialOwner.ssn,
  }))

  const payload = {
    address1: info.address1,
    address2: info.address2,
    beneficial_owners: beneficialOwnersPayload,
    business_admin: adminPayload,
    business_name: info.businessName,
    business_type: businessType,
    city: info.city,
    client_id: clientId,
    controller: controllerPayload,
    doing_business_as: info.doingBusinessAs,
    ein: info.ein,
    kyb_provider: kybProvider,
    phone: info.phoneNumber,
    postal_code: info.zip,
    state: info.state,
  }

  return payload
}

export const verifyBusiness = createAsyncThunk(
  'verifyBusiness',
  async (empty, { dispatch, getState }) => {
    const {
      verify: {
        business: { info, admin, controller },
        kybProvider,
      },
      auth: {
        client: { clientId },
      },
    } = getState() as RootState

    const businessType = getBusinessTypeViaId(info.businessType)
      .businessType as BusinessType

    const beneficialOwners = beneficialOwnersAdapter
      .getSelectors(
        (state: RootState) => state.verify.business.beneficialOwners,
      )
      .selectAll(getState() as any) as any

    const payload = formatVerifyBusinessPayload({
      admin,
      beneficialOwners,
      businessType,
      clientId,
      controller,
      info,
      kybProvider,
    })

    await dispatch(
      apiRequest({
        method: 'post',
        route: 'customer/verify_business',
        data: payload,
      }),
    )
  },
)

export enum BusinessFlowStepIndex {
  INFO = 1,
  ADDRESS = 2,
  ADMIN = 3,
  CONTROLLER = 4,
  BENEFICIAL_OWNERS = 5,
}

export const verifySlice = createSlice({
  name: 'verify',
  initialState: {
    isSubmitting: false,
    documentStatus: '',
    kybProvider: KycProviderOptions.NONE,
    kycProvider: KycProviderOptions.NONE,
    inquiryId: '',
    inquirySessionToken: '',
    personal: {
      legalFirstName: '',
      legalLastName: '',
      dateOfBirth: '',
      ssn: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
    business: {
      step: BusinessFlowStepIndex.INFO,
      beneficialOwnerRequired: false,
      controllerRequired: false,
      info: {
        businessName: '',
        doingBusinessAs: '',
        businessType: '',
        ein: '',
        website: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      admin: {
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: '',
        ssn: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      controller: {
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        dateOfBirth: '',
        ssn: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      beneficialOwners: beneficialOwnersAdapter.getInitialState({}),
    },
  },
  reducers: {
    setBeneficialOwnerRequired: (state, action) => {
      state.business.beneficialOwnerRequired = action.payload
    },
    setVerifyPersonalData: (state, action) => {
      if (action.payload.dateOfBirth) {
        state.personal.dateOfBirth = convertDateToISO(
          action.payload.dateOfBirth,
        )
      }
      if (action.payload.ssn) {
        state.personal.ssn = action.payload.ssn.replace(/[$-\s]/g, '')
      }
      state.personal.legalFirstName = action.payload.legalFirstName
      state.personal.legalLastName = action.payload.legalLastName
    },
    setBusinessFlowStep: (state, action) => {
      state.business.step = action.payload
    },
    setControllerRequired: (state, action) => {
      state.business.controllerRequired = action.payload
    },
    setBusinessInfo: (state, action) => {
      state.business.info.businessName = action.payload.businessName
      state.business.info.doingBusinessAs = action.payload.doingBusinessAs
      state.business.info.businessType = action.payload.businessType
      state.business.info.ein = action.payload.ein
      state.business.info.website = action.payload.website
      state.business.info.phoneNumber = action.payload.phoneNumber
    },
    setBusinessAddress: (state, action) => {
      state.business.info.address1 = action.payload.address1
      state.business.info.address2 = action.payload.address2
      state.business.info.city = action.payload.city
      state.business.info.state = action.payload.state
      state.business.info.zip = action.payload.zip
    },
    setBusinessAdmin: (state, action) => {
      state.business.admin.firstName = action.payload.firstName
      state.business.admin.lastName = action.payload.lastName
      state.business.admin.email = action.payload.email
      if (action.payload.dateOfBirth) {
        state.business.admin.dateOfBirth = convertDateToISO(
          action.payload.dateOfBirth,
        )
      }
      state.business.admin.ssn = action.payload.ssn
      state.business.admin.address1 = action.payload.address1
      state.business.admin.address2 = action.payload.address2
      state.business.admin.city = action.payload.city
      state.business.admin.state = action.payload.state
      state.business.admin.zip = action.payload.zip
    },
    setBusinessController: (state, action) => {
      state.business.controller.firstName = action.payload.firstName
      state.business.controller.lastName = action.payload.lastName
      state.business.controller.title = action.payload.title
      state.business.controller.email = action.payload.email
      if (action.payload.dateOfBirth) {
        state.business.controller.dateOfBirth = convertDateToISO(
          action.payload.dateOfBirth,
        )
      }
      state.business.controller.ssn = action.payload.ssn
      state.business.controller.address1 = action.payload.address1
      state.business.controller.address2 = action.payload.address2
      state.business.controller.city = action.payload.city
      state.business.controller.state = action.payload.state
      state.business.controller.zip = action.payload.zip
    },
    setRemoveBeneficialOwner: (state, action) => {
      beneficialOwnersAdapter.removeOne(
        state.business.beneficialOwners,
        action.payload,
      )
    },
    setAddBeneficialOwner: (state, action) => {
      const beneficialOwner = {
        ...action.payload,
        dateOfBirth: action.payload.dateOfBirth
          ? convertDateToISO(action.payload.dateOfBirth)
          : '',
      }

      beneficialOwnersAdapter.addOne(
        state.business.beneficialOwners,
        beneficialOwner,
      )
    },
    setKycData: (state, action) => {
      state.documentStatus = action.payload.document_status || ''
      state.inquiryId = action.payload.inquiry_id || ''
      state.inquirySessionToken = action.payload.inquiry_session_token || ''

      if (action.payload.user_type === UserType.BUSINESS) {
        if (action.payload.kyc_provider) {
          state.kybProvider = action.payload.kyc_provider
        }
        const {
          business_info = {},
          business_admin = {},
          business_controller = {},
        } = action.payload.business_profile || {}

        const businessInfo = {
          businessName: business_info.business_name || '',
          doingBusinessAs: business_info.doing_business_as || '',
          businessType: business_info.business_type || '',
          ein: business_info.ein || '',
          website: business_info.website || '',
          phoneNumber: business_info.phone || '',
          address1: business_info.address1 || '',
          address2: business_info.address2 || '',
          city: business_info.city || '',
          state: business_info.state || '',
          zip: business_info.postal_code || '',
        }

        state.business.info = businessInfo

        const businessAdmin = {
          firstName: business_admin.first_name || '',
          lastName: business_admin.last_name || '',
          email: business_admin.email || '',
          dateOfBirth: business_admin.date_of_birth || '',
          ssn: business_admin.ssn || '',
          address1: business_admin.address1 || '',
          address2: business_admin.address2 || '',
          city: business_admin.city || '',
          state: business_admin.state || '',
          zip: business_admin.postal_code || '',
        }

        state.business.admin = businessAdmin

        if (business_controller) {
          const businessController = {
            firstName: business_controller.first_name || '',
            lastName: business_controller.last_name || '',
            email: business_controller.email || '',
            title: business_controller.title || '',
            dateOfBirth: business_controller.date_of_birth || '',
            ssn: business_controller.ssn || '',
            address1: business_controller.address1 || '',
            address2: business_controller.address2 || '',
            city: business_controller.city || '',
            state: business_controller.state || '',
            zip: business_controller.postal_code || '',
          }

          state.business.controller = businessController
        }
      } else {
        if (action.payload.kyc_provider) {
          state.kybProvider = action.payload.kyc_provider
        }
        state.personal.dateOfBirth = action.payload.date_of_birth
          ? convertDateToISO(action.payload.date_of_birth)
          : ''
        state.personal.legalFirstName = action.payload.legal_first_name || ''
        state.personal.legalLastName = action.payload.legal_last_name || ''
        state.personal.address1 = action.payload.address1 || ''
        state.personal.address2 = action.payload.address2 || ''
        state.personal.city = action.payload.city || ''
        state.personal.state = action.payload.state || ''
        state.personal.zip = action.payload.postal_code || ''
      }
    },
    setKybProvider: (state, action) => {
      state.kybProvider = action.payload
    },
    setKycProvider: (state, action) => {
      state.kycProvider = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(verify.pending, state => {
      state.isSubmitting = true
    })
    builder.addCase(verify.fulfilled, (state, action) => {
      state.isSubmitting = false
      state.personal.address1 = action.payload.address1 ?? ''
      state.personal.address2 = action.payload.address2 ?? ''
      state.personal.city = action.payload.city ?? ''
      state.personal.state = action.payload.state ?? ''
      state.personal.zip = action.payload.zip ?? ''
    })
    builder.addCase(verify.rejected, state => {
      state.isSubmitting = false
    })

    builder.addCase(verifyBusiness.pending, state => {
      state.isSubmitting = true
    })
    builder.addCase(verifyBusiness.fulfilled, state => {
      state.isSubmitting = false
    })
    builder.addCase(verifyBusiness.rejected, state => {
      state.isSubmitting = false
    })
  },
})

export const {
  setAddBeneficialOwner,
  setBeneficialOwnerRequired,
  setBusinessAddress,
  setBusinessAdmin,
  setBusinessController,
  setBusinessFlowStep,
  setBusinessInfo,
  setControllerRequired,
  setKycData,
  setKybProvider,
  setKycProvider,
  setRemoveBeneficialOwner,
  setVerifyPersonalData,
} = verifySlice.actions

export const selectDocumentStatus = (state: RootState): string =>
  state.verify.documentStatus

export const selectKybProvider = (state: RootState): KycProviderOptions =>
  state.verify.kybProvider
export const selectKycProvider = (state: RootState): KycProviderOptions =>
  state.verify.kycProvider
export const selectIsKybDelegated = (state: RootState): boolean =>
  state.verify.kybProvider === KycProviderOptions.SELF
export const selectInquiryId = (state: RootState): string =>
  state.verify.inquiryId
export const selectInquirySessionToken = (state: RootState): string =>
  state.verify.inquirySessionToken
export const selectBusinessInfo = (state: RootState): BusinessInfo =>
  state.verify.business.info
export const selectBusinessAdmin = (state: RootState): BusinessAdmin =>
  state.verify.business.admin
export const selectBusinessController = (
  state: RootState,
): BusinessController => state.verify.business.controller
export const selectBusinessFlowStep = (
  state: RootState,
): BusinessFlowStepIndex => state.verify.business.step
export const selectIsControllerRequired = (state: RootState): boolean =>
  state.verify.business.controllerRequired

export const selectVerifyPersonal = (
  state: RootState,
): {
  legalFirstName: string
  legalLastName: string
  dateOfBirth: string
  ssn: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
} => state.verify.personal
export const selectIsSubmitting = (state: RootState): boolean =>
  state.verify.isSubmitting
export const beneficialOwnersSelectors = beneficialOwnersAdapter.getSelectors(
  (state: RootState) => state.verify.business.beneficialOwners,
)

export default verifySlice.reducer
