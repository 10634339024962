import React from 'react'
import { useSelector } from 'react-redux'

import ConnectInstitutionModule from 'src/features/accounts/ConnectInstitutionModule'
import ContinueButton from './ContinueButton'
import { selectCanConnectAccounts } from 'src/features/auth/authSlice'

const ConnectInstitution = () => {
  const canConnectAccounts = useSelector(selectCanConnectAccounts)

  return (
    <>
      {canConnectAccounts && <ConnectInstitutionModule />}
      <ContinueButton continueOverride={!canConnectAccounts} />
    </>
  )
}

export default ConnectInstitution
